.item-card{
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(182, 182, 182, 0.856) ;
    overflow: hidden;
    cursor: pointer;

}

@media(max-width:780px){
    .item-card{
        flex-direction: column;
        height:200px;
        
        /* border: 1px solid blue; */
    }

    .item-card .image{
        height: 200px !important;
        overflow: hidden;
        width: 100% !important;
        /* border: 1px solid red; */
        margin: 0;
        display: flex;
        justify-content: center;
        background-size: cover !important;
        background-position: center;

    }
    .item-card .image img{
        height: 100% !important;
        overflow: hidden;
        width: auto !important;
        /* border: 1px solid red; */

    }
    .item-card .text{
       padding: 10px;
       margin: 0;
       /* border: 1px solid yellow; */
       height: fit-content;
       font-size: .9rem !important;

    }
    .item-name-text{
      
       font-size: 1rem !important;

    }
}
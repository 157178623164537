.menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: repeat(2, 120px); */

  gap: 20px;
  padding: 1rem 0;
}

@media (max-width: 1000px) {
  .menu {
    grid-template-columns: 1fr 1fr;
  }

  .right {
    display: none !important;
  }

  .menu-links {
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 1.5em 1.5em;
    width: 50%;
  }
  .menu-icon{
    display: flex !important;
}
}
.top,
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 2rem;
}

.--red-border {
  border: 1px solid red;
}

.left,
.right {
  display: flex;
  align-items: center;
  /* align-items: flex-end; */
  gap: 1rem; 
}

.menu-header .top {
  font-size: 0.9rem;
}

.nav-links {
  display: flex;
}

ul li:first-child {
  list-style: none;
}

@media (max-width: 780px) {
  .menu-tab-body {
    flex-direction: column;
    overflow-x: hidden;
  }

  .side-menu-bar {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    height: fit-content;
    overflow-x: auto;
    align-items: center;
    box-shadow:0px 0px 5px rgba(0, 0, 0, 0.351);
    width:calc(100% - 8px)
  }

  .side-menu-tab {
    width: fit-content;
    height: fit-content;
  
  }

  .categories-title {
    display: none;
  }
}

/* header  */
.--display-none {
  display: none;
  transition:.6s;

}

.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  /* background: white; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.726);
  /* transform: ${props => props.show ? 'translateX(0)':'translateX(100%)'};
    transition: transform .6s;
   */
   transition:.6s;

}

.menu-icon{
    display: none;
    cursor: pointer;
}

.menu-close-icon{
    cursor: pointer;
}

@media(max-width:780px){
.mobile-width{
    width: 90%;
}

.cart-table{
  width: 100%;
}

}
.reservation{
  /* background-image: url('https://menu.qranbessa.net/assets/img/photos/reservation.jpg'); */
  background-color: #f5f5f5;

  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  background-attachment: fixed;
}
@media(max-width:780px){
  .reservation{
  
    padding-top: 0px;
  }
}
.form-container {
    max-width: 400px;
    margin: 4rem auto;
    padding: 20px;
    background-color: #f5f5f5;
    /* background-color: #f5f5f5; */
    border-radius: 5px;
    text-align: left;
    display: flex ;
    flex-direction: column;
    gap: 1rem;
    @media (min-width: 768px){
        max-width: 600px;
        margin: 4rem auto;
    }

    @media (min-width: 920px){
        max-width: 400px;
        margin: 4rem auto;
    }

  }

  h1 {
    margin-top: -.5rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 1.2rem;
  }
  
  button[type="submit"] {
    width: 100%;
    /* background-color: #4caf50; */
    /* color: #F1E4C3; */
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    display: inline;
  }
  button[type="submit"]:hover {
    color: #C6A969;
  }

 
  /* .form-container {
    max-width: 400px;
    margin: 4rem auto;
    padding: 40px;
    background-color: #f5f5f5;
    border-radius: 5px;
    text-align: left;

    @media (min-width: 768px){
        max-width: 600px;
        margin: 4rem auto;
    }

    @media (min-width: 920px){
        max-width: 400px;
        margin: 4rem auto;
    }
  }

  .texts {
    margin-bottom: 3rem;

    h1 {
        margin-top: -.5rem;
        margin-bottom: -.5rem;
        font-size: 1.6rem;
      }
    
      p {
        color: gray;
        font-size: .9rem;
    
        span {
            font-weight: bold;
            font-size: 1.2rem;
        }
      }
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    margin-bottom: 1.2rem;
    background-color: #f5f5f5;
  }
  
  button[type="submit"] {
    width: 100%;
    background-color: #4caf50;
    color: #F1E4C3;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  button[type="submit"]:hover {
    color: #C6A969;
  } */

 
  .datetime{
    display: flex;
    gap:  1rem;
    justify-content: stretch;
    align-items: stretch;
  }
  .datetime div{
    flex-grow: 1;
  }
